import React, { memo } from 'react'
import { navigate } from 'gatsby'
import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { capitalize } from 'lodash'
import { CacheUser, ResponseType, ShareModalState } from '@types'
import { AccountLayout, MobileAccountLayout } from '@layouts'
import { user } from '@graphql'
import { AccountPage } from '@pages/components'
import { useModal, useWindowSize } from '@hooks'
import './portfolio.scss'

interface AccountPortfolioProps extends RouteComponentProps {}

const AccountPortfolio: React.FC<AccountPortfolioProps> = () => {
  const { openModal: openShareModal } = useModal<ShareModalState>('ShareModal')
  const { data, loading } = useQuery<ResponseType<CacheUser>>(user.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })

  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])

  const currentUser = data && data.res

  if (!loading && !currentUser) navigate('/home/')
  if (!currentUser) return null

  const basicInfo = currentUser!.basicInfo
  const { flex, dexterity, gripSize, height } = basicInfo

  const Layout = isMobileScreen ? MobileAccountLayout : AccountLayout

  const handleShare = () => {
    const apiUrlProduction = process.env.GATSBY_API || ''
    const processUrlProduction = process.env.GATSBY_PROCESS_URL

    openShareModal({
      title: 'Share your locker with other golfers',
      shareUrl: apiUrlProduction.replace('/api', `/user-portfolio/${currentUser.id}`),
      shareText: `Hey - check out this locker on ${processUrlProduction}`
    })
  }

  return (
    <Layout pageClassName={'account-user-portfolio'} title={'My Locker'}>
      {!isMobileScreen && <h6>MY LOCKER</h6>}
      <div className={'account-section'}>
        <div className={'account-section-header'}>
          <strong className={'subtitle2 uppercase'}>Player Info</strong>
        </div>
        <div className={'account-section-fields'}>
          <div className={'account-section-field'}>
            <div className={'account-section-field-title'}>
              <strong className={'uppercase grey'}>Flex</strong>
            </div>
            <p>{capitalize(flex)}</p>
          </div>
          <div className={'account-section-field'}>
            <div className={'account-section-field-title'}>
              <strong className={'uppercase grey'}>Dexterity</strong>
            </div>
            <p>{capitalize(dexterity)}</p>
          </div>
          <div className={'account-section-field'}>
            <div className={'account-section-field-title'}>
              <strong className={'uppercase grey'}>Grip size</strong>
            </div>
            <p>{capitalize(gripSize)}</p>
          </div>
          <div className={'account-section-field'}>
            <div className={'account-section-field-title'}>
              <strong className={'uppercase grey'}>Height</strong>
            </div>
            <p>{height}</p>
          </div>
        </div>
      </div>
      <AccountPage.Bag user={currentUser} handleShare={handleShare} isShareButton />
    </Layout>
  )
}

export default memo(AccountPortfolio)
